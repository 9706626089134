import React from 'react';
import profileSrc from './maxrozen_profile_square.jpg';

export default ({ id = 'social-card', title = 'Missing Title' }) => {
  return (
    <div id={id} className="SocialCard">
      <div className="SocialCardInner">
        <div style={{ marginTop: '32px' }}></div>
        <div
          style={{
            fontSize: '48px',
            fontWeight: 700,
            maxWidth: '70%',
            textAlign: 'center',
          }}
        >
          {title}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '32px',
          }}
        >
          <img
            src={profileSrc}
            alt="Max Rozen"
            style={{
              width: '32px',
              height: '32px',
              borderRadius: '100%',
            }}
          />
          <div style={{ paddingLeft: '16px' }}>Max Rozen | @rozenmd</div>
        </div>
      </div>
    </div>
  );
};
