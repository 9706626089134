import React from 'react';
import queryString from 'query-string';
import SocialCard from './SocialCard';

import './SocialCard.css';

function App() {
  const location = typeof window !== undefined ? window.location : {};
  const data = queryString.parse(location.search);
  const { width = '1136px', height = '566px' } = data;

  const styles = {
    width: width,
    height: height,
  };
  return (
    <div className="App" style={styles}>
      <SocialCard {...data} />
    </div>
  );
}

export default App;
